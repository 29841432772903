import { Box, Button, Flex, Heading, Select } from "@chakra-ui/react";
import React, { useState } from "react";
import { CartItem } from "../pages/cart";

const generateList = () => {
	var list = [];
	for (var i = 5; i < 105; i += 5) {
		list.push(i)
	}
	return list
}

export const ProductForm = (props: { type: string, size?: string, id: string }) => {
	const [totalQuantity, setTotalQuantity] = useState(5);

	const totalCost = () => {
		let total;
		switch (props.type) {
			case "Pellets":
				total = 10 + (5 * totalQuantity) - (((totalQuantity - 5) / 5) * 12)
				break
			case "Slices":
				total = 15 + (5 * totalQuantity) - (((totalQuantity - 5) / 5) * 13)
				break
			default:
				total = 10 + (4 * totalQuantity) - (((totalQuantity - 5) / 5) * 5)
		}

		return total
	}

	const addToCart = () => {
		var currentCart = localStorage.getItem("cart")
		if (currentCart === "") {
			let item: CartItem[] = [{
				ID: props.id,
				ProductName: props.type + " - " + props.size,
				Quantity: totalQuantity,
				Cost: totalCost()
			}]

			localStorage.setItem("cart", JSON.stringify(item))
		} else {
			var cartObject = JSON.parse(currentCart || "[]")
			let item: CartItem = {
				ID: props.id,
				ProductName: props.type + (props.type === "Gel Packs" ? "" : " - " + props.size),
				Quantity: totalQuantity,
				Cost: totalCost()
			}
			var toStore = [...cartObject, item]
			localStorage.setItem("cart", JSON.stringify(toStore))
		}
		window.location.reload()
	}

	return (
		<Box mt={"2em"}>
			<Flex>
				<Select mr={"1em"} onChange={(e) => {
					setTotalQuantity(parseInt(e.target.value))
				}}>
					{generateList().map(num => {
						return (

							<option>{num}</option>)
					})}

				</Select>
				<Heading size="l">Total: £{totalCost()}</Heading>
			</Flex>
			<Button onClick={addToCart}>Add to cart</Button>
		</Box>
	)
}