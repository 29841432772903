import { Badge, Box, Image, Link } from "@chakra-ui/react";
import React from "react";



export const ProductTile = (props: { name: string; size: string, id: string, page?: boolean, imageLink: string }) => {


	return (
		<Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" h="600px">
			<Image src={props.imageLink} alt="Ice" objectFit="cover" boxSize={"400px"}></Image>
			<Box p="6" bg="white" h="200px">
				<Box d="flex" alignItems="baseline">
					<Badge borderRadius="full" px="2" colorScheme="teal">
						{props.size}
					</Badge>
				</Box>

				<Box
					mt="1"
					mb="1"
					fontWeight="semibold"
					as="h4"
					lineHeight="tight"
					isTruncated
				>
					{props.name}
				</Box>
				{!props.page ?
					<Link


						variant="ghost"
						href={"/products/" + props.id}>
						View
					</Link> : null}

			</Box>
		</Box>
	)
}