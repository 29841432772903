import { Box, Heading, HStack, Image, useMediaQuery, VStack, Spinner } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import FadeIn from 'react-fade-in'
import { useParams } from "react-router-dom"
import { ProductForm } from "../components/productForm"
import { ProductTile } from "../components/productTile"
import { GelDescription, PelletDescription16, PelletDescription3, SliceDescription1, SliceDescription500 } from "../data/descriptions"
import { Product } from "./products"

const getProduct = (id: string) => {
	return fetch("https://dnl-3yxkjexpra-nw.a.run.app/api/products/" + id).then(data => data.json())
}

type params = {
	id: string;
}

const ProductPage = () => {
	let imageLink = "";

	const [product, setProduct] = useState<null | Product>()
	const { id } = useParams<params>()
	const [isDesktop] = useMediaQuery([
		"(min-width: 940px)",
	])

	let body;
	let description;
	useEffect(() => {
		let mounted = true;

		getProduct(id).then(item => {
			if (mounted) {
				setProduct(item)
			}
		})
		return () => {
			mounted = false
		}
	}, [id])

	if (product) {

		if (product.product_type === "Pellets") {
			if (product.product_size === "16mm") {
				imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/slide_1.jpg"
			} else {
				imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/3mm-pallets.jpg"
			}
		} else if (product.product_type === "Slices") {
			imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/dry-ice-slice.jpg"
		} else {
			imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/gel_product.jpg"
		}

		switch (product.product_type) {
			case "Pellets":
				if (product.product_size === "16mm") {
					description = <PelletDescription16 />
				} else {
					description = <PelletDescription3 />
				}
				break
			case "Slices":
				if (product.product_size === "1kg") {
					description = <SliceDescription1 />
				} else {
					description = <SliceDescription500 />
				}
				break
			default:
				description = <GelDescription />

		}


		if (isDesktop) {
			body = (
				<FadeIn>

					<HStack justifyContent="center" spacing="2em">
						<ProductTile name={product.product_type} size={product.product_size} id={product.product_id} imageLink={imageLink} page={true}></ProductTile>
						<Box h="100%" w="60vh" bg="white" p="16" className="descContainer">
							<Heading size="3xl" mb={"2rem"}>Dry Ice {product.product_type}, {product.product_size}</Heading>
							{description}
							<ProductForm type={product.product_type} size={product.product_size} id={product.product_id} />
						</Box>
					</HStack>
				</FadeIn>)
		} else {
			body = (
				<FadeIn>

					<VStack justifyContent="center">
						<Image src={imageLink} alt="Ice" objectFit="fill"></Image>
						<Box h="100%" w="60vh" bg="white" p="24" className="descContainer">
							<Heading size="3xl" mb={"2rem"}>Dry Ice {product.product_type}, {product.product_size}</Heading>
							{description}
							<ProductForm type={product.product_type} size={product.product_size} id={product.product_id} />
						</Box>
					</VStack>
				</FadeIn >
			)
		}
	} else {
		body = (
			<Box w="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
				<Spinner />
			</Box>
		)
	}
	return (
		<Box h="100%" w="100vw" justifyContent="center" alignItems="center" p={16}>
			{body}
		</Box>
	)
}

export default ProductPage