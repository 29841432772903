import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import React from "react"


export const PelletDescription16 = () => {
	return (
		<Box>
			<Text>16mm pellets are our largest size of pellet.</Text>
			<Text>The lengths of the pellets vary from 1 - 5cm long.</Text>
			<Text>They are a multipurpose form of ice, but are most commonly used for:</Text>
			<ul>
				<li><Text>Temperature controlled logistics</Text></li>
				<li><Text>Sample distribution</Text></li>
				<li><Text>Special effects</Text></li>
				<li><Text>Freeze branding</Text></li>
				<li><Text>Shrink fitting</Text></li>
				<li><Text>Freeze drying</Text></li>
				<li><Text>Cold grinding</Text></li>
				<li><Text>Plumbing pipe repairs</Text></li>

			</ul>
		</Box>
	)
}

export const PelletDescription3 = () => {
	return (
		<Box>
			<Text>3mm pellets are our smallest size of pellet.</Text>
			<Text>The lengths of the pellets vary from 1 - 8cm long.</Text>
			<Text mb={"2em"}>They are predominantly used for Blast Cleaning, but are also used for:</Text>
			<UnorderedList>
				<ListItem><Text>Cold Grinding</Text></ListItem>
				<ListItem><Text>Freeze Drying</Text></ListItem>
				<ListItem><Text>In-process Cooling for the Chemical & Food Industry</Text></ListItem>

			</UnorderedList>
		</Box>
	)
}

export const SliceDescription1 = () => {
	return (
		<Box>
			<Text>Dimensions: 210x125x25 mm.</Text>
			<Text>These are the most frequently used slices.</Text>
			<Text>Due to their durability and convenience, they are mostly used in:</Text>
			<UnorderedList>
				<ListItem><Text>Inflight catering</Text></ListItem>
				<ListItem><Text>Special effects</Text></ListItem>
				<ListItem><Text>Temperature controlled logistics and storage</Text></ListItem>
				<ListItem><Text>Freeze drying</Text></ListItem>
				<ListItem><Text>Freeze branding</Text></ListItem>
			</UnorderedList>
		</Box>
	)
}

export const SliceDescription500 = () => {
	return (
		<Box>
			<Text>Dimensions: 105x125x25 mm.</Text>
			<Text>These are the most popular amongst caterers and flights.</Text>
			<Text>Due to their small size and compact style, they are mostly used in:</Text>
			<UnorderedList>
				<ListItem><Text>Inflight catering</Text></ListItem>
				<ListItem><Text>Special effects</Text></ListItem>
				<ListItem><Text>Temperature controlled logistics and storage</Text></ListItem>
				<ListItem><Text>Freeze drying</Text></ListItem>
			</UnorderedList>
		</Box>
	)
}

export const GelDescription = () => {
	return (
		<Box>
			<Text>Dimensions: 270x130x35 mm.</Text>
			<Text>These water based phase change cool packs can be refrigerated or frozen.</Text>
			<UnorderedList>
				<ListItem><Text>Non toxic formula</Text></ListItem>
				<ListItem><Text>Manufactured to ISO9001:2000 standards</Text></ListItem>
			</UnorderedList>
		</Box>
	)
}

