import { Box, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/react";
import React from "react";

const Safety = () => {
	const [showHamburger] = useMediaQuery([
		"(min-width: 1080px)",
	])
	return (
		<Box w="100vw" display="flex" justifyContent="center" alignItems="center" p={8}>

			<VStack alignItems="start" w={showHamburger ? "80%" : "100%"}>
				<Box p={8} w="100%" rounded="2xl" boxShadow="md" bg="#056cdf">
					<Heading color="white" fontSize={["32px", "36px", "40px", "44px"]}>Safety</Heading>
				</Box>
				<SimpleGrid alignItems="start" columns={[1, 1, 1, 1, 2]} spacing="2em" fontSize={["12px", "18px", "22px", "28px"]} p={8} w="100%" color="black" >
					<VStack>
						<Box rounded="2xl" boxShadow="md" p={8} w="100%" bg="white" className="hover-box">
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Handling</Heading>
							<Text>Dry ice is incredibly cold! (-78 degrees) - always wear protective gloves and thermal gloves whenever handling it. Whilst brief contact is harmless, prolonged contact can lead to frostbite.</Text>
						</Box>
						<Box rounded="2xl" boxShadow="md" p={8} w="100%" bg="white" className="hover-box">
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Storage</Heading>
							<Text>
								Store Dry Ice in an insulated container. The thicker the insulation, the slower it will sublimate. Dry Ice should not be stored in an airtight containers. Sublimation of Dry Ice to Carbon Dioxide gas will cause any airtight container to expand or possibly explode. Keep proper air ventilation wherever Dry Ice is stored. Do not store Dry Ice in unventilated rooms, cellars, autos or boat holds. The sublimated Carbon Dioxide gas will sink to low areas and replace oxygenated air. This could cause suffocation if breathed in exclusively. Do not store Dry Ice in a freezer of refrigerator as the extremely cold temperature will cause the thermostat to turn off the freezer. It will keep everything frozen in the freezer but it will be used up at a faster rate. It is the perfect thing to use if your refrigerator breaks down in an emergency. There are Commercial Storage Containers available to store Dry Ice.
							</Text>
						</Box>

					</VStack>
					<VStack>
						<Box rounded="2xl" boxShadow="md" p={8} w="100%" bg="white" className="hover-box">
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Disposal</Heading>
							<Text>
								Unwrap and leave Dry Ice at room temperature in a well-ventilated area,It will sublimate from a solid to a gas with no residue left. This way Dry Ice is very eco friendly.
							</Text>
						</Box>
						<Box rounded="2xl" boxShadow="md" p={8} w="100%" bg="white" className="hover-box">
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Recommended PPE</Heading>
							<ul>
								<li>Thermal gloves</li>
								<li>Safety glasses</li>
								<li>Long sleeved tops and long trousers</li>
								<li>Safety boots</li>
							</ul>
						</Box>
						<Box rounded="2xl" boxShadow="md" p={8} w="100%" bg="white" className="hover-box">
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Ventilation</Heading>
							<Text>
								Normal air is 78% Nitrogen, 21% Oxygen and only 0.035% Carbon Dioxide. If the concentration of carbon dioxide in the air rises above 0.5%, it can cause Asphyxiation. Smaller concentrations can cause quicker breathing and headaches but is otherwise not harmful. If Dry Ice has been in a closed auto, van, room, or walk-in, for more than 10 minutes, open doors and allow adequate ventilation before entering. Leave area containing Dry Ice immediately if you start to pant and breath quickly, develops a headache or your fingernails or lips start to turn blue. This is the sign that you have breathed in too much CO2 and not enough oxygen. Dry Ice CO2 is heavier than air and will accumulate in low spaces. Do not enter closed storage areas that have or have had stored Dry Ice before airing out completely.
							</Text>
						</Box>
					</VStack>
				</SimpleGrid>
			</VStack>
		</Box>
	);
}

export default Safety;