import { Box, Heading, SimpleGrid, VStack } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/react";
import React from "react";

const Usage = () => {
	const [showHamburger] = useMediaQuery([
		"(min-width: 1080px)",
	])
	return (
		<Box w="100vw" display="flex" justifyContent="center" alignItems="center" p={8}>
			<VStack alignItems="start" w={showHamburger ? "80%" : "100%"}>
				<Box p={8} w="100%" bg="#056cdf" rounded="2xl" boxShadow="md">
					<Heading fontSize={["32px", "36px", "40px", "44px"]} color="white">Dry Ice and Usage</Heading>
				</Box>
				<SimpleGrid alignItems="start" spacing="2em" fontSize={["12px", "18px", "22px", "28px"]} p={8} w="100%" color="black" columns={[1, 1, 1, 1, 2]}>
					<Box boxShadow="xl" rounded="2xl" bg="white" p={8} className="hover-box">
						<Heading mb="0.5em" size="xl" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text">What is Dry Ice?</Heading>
						<ul>
							<li>Dry ice is the solid form of carbon dioxide. It exists at -78 degrees celsius, as opposed to regular ice, that freezes at 0 degrees celsius.</li>
							<li>It's formed when liquid carbon dixoide is released from compression. However, dry ice does not melt back into a liquid form, but instead transforms into a gas through sublimation.</li>
							<li>Dry ice is colourless, tasteless, odourless and bacteriostatic.</li>
							<li>Dry ice was discovered by Charles Thilorier, and it's first patent was filed in 1924. Since, it has been found on other planets and comets!</li>
						</ul>
					</Box>
					<Box boxShadow="xl" rounded="2xl" bg="white" p={8} className="hover-box">
						<Heading mb="0.5em" size="xl" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text">Use cases</Heading>
						<ul>
							<li>Temperature controlled storage and logistics.</li>
							<li>Sample distribution within the pharmaceutical industry.</li>
							<li>Special FX.</li>
							<li>Shrink fitting.</li>
							<li>Freeze branding</li>
							<li>Cold grinding.</li>
							<li>Food transport for airlines.</li>
							<li>Demonstrations within schools.</li>
						</ul>
					</Box>
				</SimpleGrid>
			</VStack>

		</Box>
	);
}

export default Usage;