import { Box, Heading, SimpleGrid } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { ProductTile } from "../components/productTile"
import FadeIn from 'react-fade-in';


const getProducts = () => {
	return fetch("https://dnl-3yxkjexpra-nw.a.run.app/api/products").then(data => data.json())
}

export type Product = {
	product_id: any,
	product_type: any,
	product_size: any
}

const Products = () => {
	const [products, setProducts] = useState([])
	useEffect(() => {
		let mounted = true;
		getProducts().then(items => {
			if (mounted) {
				setProducts(items)
			}
		})
		return () => {
			mounted = false
		}
	}, [])
	if (products) {

		return (
			<Box w="100vw" justifyContent="center" p={16}>
				<Heading mb={"2rem"}>All Products</Heading>
				<SimpleGrid columns={[1, 1, 2, 3, 4]} spacing={"40px"} justifyContent="center">

					{products.map((product: Product) => {
						let imageLink = "";

						if (product.product_type === "Pellets") {
							if (product.product_size === "16mm") {
								imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/slide_1.jpg"
							} else {
								imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/3mm-pallets.jpg"
							}
						} else if (product.product_type === "Slices") {
							imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/dry-ice-slice.jpg"
						} else {
							imageLink = "https://dryicenationwide.co.uk/wp-content/uploads/2020/04/gel_product.jpg"
						}
						return (
							<FadeIn>
								<ProductTile name={product.product_type} size={product.product_size} id={product.product_id} imageLink={imageLink}></ProductTile>
							</FadeIn>
						)
					})}
				</SimpleGrid>

			</Box>
		)
	} else {
		return (
			<Box h="100vh" w="100vw" justifyContent="center" display="flex" alignItems="center" p={16}>
				<Heading>Loading</Heading>
			</Box>
		)
	}
}

export default Products