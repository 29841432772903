import { extendTheme } from "@chakra-ui/react"
// 2. Add your color mode config

// 3. extend the theme
const theme = extendTheme({
	styles: {
		global: {
			body: {
				bg: "gray.100"
			}
		}
	}
})
export default theme