import { Box, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/react";
import { GoogleApiWrapper } from 'google-maps-react';
import React from "react";
import GoogleMap from "./map";



const Contact = () => {

	const [showHamburger] = useMediaQuery([
		"(min-width: 1080px)",
	])
	return (
		<Box w="100vw" mb="2em" display="flex" justifyContent="center" alignItems="center" p={8}>
			<VStack w={showHamburger ? "75%" : "100%"} alignItems="start">
				<Box p={8} w="100%" rounded="2xl" boxShadow="md" bg="#056cdf">
					<Heading color="white" fontSize={["32px", "36px", "40px", "44px"]}>Safety</Heading>
				</Box>
				<SimpleGrid columns={[1, 1, 1, 1, 1, 2]} alignItems="center" spacing="1em" w="100%">
					<VStack bg="white" alignItems="start" spacing="2em" fontSize={["12px", "18px", "22px", "28px"]} p={8} w="100%" rounded="2xl" boxShadow="md" color="black" >
						<Box>
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Phone</Heading>
							<Text>
								+44(0)118 934 4833						</Text>
						</Box>
						<Box>
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Email</Heading>
							<Text>
								sales@dryicenationwide.co.uk						</Text>
						</Box>
						<Box>
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Registered Office</Heading>
							<Text> 66, Chertsey Road,
								Ashford,
								TW15 1SG
							</Text>
						</Box>
						<Box>
							<Heading mb="0.5em" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text" size="xl">Our plant</Heading>
							<Text>
								Unit 9, Manor Farm, Manor Farm Road,
								Shurlock Row, Reading,
								RG10 0PY						</Text>
						</Box>
					</VStack>
					<Box p={8} w="100%" rounded="2xl" h="100%">
						<GoogleMap />
					</Box>
				</SimpleGrid>
			</VStack>
		</Box>
	);
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyB3ZDWyz9LTTOdAQr-wEZ4GBP288YtnalI'
})(Contact);