import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box, Button, ChakraProvider, Flex,
  Heading,
  HStack, Link, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Stack,
  Text,
  useColorMode, useDisclosure,
  useMediaQuery,
  VStack
} from "@chakra-ui/react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import * as React from "react";
import { ReactNode } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ReactComponent as Logo } from './assets/logo.svg';
import BG from './assets/slide_1.jpeg';
import './main.css';
import Cart, { CartItem } from "./pages/cart";
import Checkout from "./pages/checkout";
import Contact from "./pages/contact";
import FAQs from "./pages/faqs";
import ProductPage from "./pages/productPage";
import Products from "./pages/products";
import Safety from "./pages/safety";
import Usage from "./pages/usage";
import theme from './theme';



const Links = ['Usage', 'Safety', 'MSDS', 'Products', 'Contact'];

export const NavLink = ({ children }: { children: ReactNode }) => {

  if (children?.toString().toLowerCase() === "msds") {
    return (
      <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: 'gray.200',
        }}
        href={"http://dryicenationwide.co.uk/wp-content/uploads/2020/01/sds-dnl-201219-1.pdf"}>
        {children}
      </Link>
    )
  } else {
    return (
      <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: 'gray.200',
        }}
        href={"/" + children?.toString().toLowerCase()}>
        {children}
      </Link>
    )
  }
};
export const App = () => {
  AOS.init();

  const { colorMode, toggleColorMode } = useColorMode();
  if (colorMode === "dark") {
    toggleColorMode()
  }
  const { isOpen } = useDisclosure();
  const [showHamburger] = useMediaQuery([
    "(min-width: 1080px)",
  ])

  const getCartQ = () => {
    var cart: CartItem[] = JSON.parse(localStorage.getItem("cart") || "[]")
    return cart.length
  }

  return (
    <ChakraProvider theme={theme}>

      <BrowserRouter>
        <Box bg={'white'} w="80%" zIndex={2} rounded="2xl" margin="auto" mt="0.5em" boxShadow="xl" style={{
          position: "sticky",
          top: 10
        }}>
          <Flex h={"16"} alignItems={'center'} justifyContent={'space-between'} px={8}>
            <HStack spacing={8} alignItems={'center'} mr="2.5vw">
              <Box><a href="/"><Logo height="16px" width="auto" /></a></Box>
              {showHamburger ?
                <HStack
                  as={'nav'}
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}>
                  {Links.map((link) => (
                    <NavLink key={link}>{link}</NavLink>
                  ))}
                </HStack> :
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                        Menu
                      </MenuButton>
                      <MenuList>
                        {Links.map((link) => <MenuItem><NavLink key={link}>{link}</NavLink></MenuItem>)}
                      </MenuList>
                    </>
                  )}
                </Menu>}
            </HStack>
            <Flex alignItems={'center'}>
              <Button
                variant={'solid'}
                colorScheme={'red'}
                size={'sm'}
                mr={4}
              >
                <Link href="/cart">Cart: {getCartQ()}</Link>
              </Button>
            </Flex>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                {Links.map((link) => (
                  <NavLink key={link}>{link}</NavLink>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>

        <Switch>
          <Route exact path="/">
            <Box h="100%" w="100%" display="flex" flexDirection="column" py={8} alignItems="center" backgroundSize="fill">
              <div className={"snow"}>
                <i className={"snow_flake"}></i>
                <i className={"snow_flake"}></i>
                <i className={"snow_flake"}></i>
                <i className={"snow_flake-600px"}></i>
                <i className={"snow_flake-600px"}></i>
                <i className={"snow_flake-600px"}></i>
                <i className={"snow_flake-600px"}></i>
                <i className={"snow_flake-768px"}></i>
                <i className={"snow_flake-768px"}></i>
                <i className={"snow_flake-1024px"}></i>
                <i className={"snow_flake-1024px"}></i>
                <i className={"snow_flake-1024px"}></i>
                <i className={"snow_flake-1280px"}></i>
                <i className={"snow_flake-1280px"}></i>
                <i className={"snow_flake-1366px"}></i>
                <i className={"snow_flake-1600px"}></i>
                <i className={"snow_flake-1600px"}></i>
                <i className={"snow_flake-1800px"}></i>
                <i className={"snow_flake-1800px"}></i>
                <i className={"snow_flake-1920px"}></i>
                <i className={"snow_flake-1920px"}></i>
              </div>
              <SimpleGrid columns={1} alignItems="center" maxW="75%" justifyContent="center" h="100%" data-aos="fade-up" spacing="8px">
                <VStack alignItems="center" bg="rgba(255,255,255,1)" rounded="2xl" py={16} px={8} className="hover-box">
                  <Heading textAlign="center" size="3xl" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text">Dry Ice Nationwide</Heading>
                  <Heading size="2xl" textAlign="center">A leading manufacturer and supplier of dry ice in the UK.</Heading>
                </VStack>
                <HStack alignItems="center" justifyContent="center" maxH="50vh">
                  <img alt="ice" src={BG} style={{
                    minHeight: '30vh',
                    maxHeight: "30vh",
                    borderRadius: 20,
                    width: "50%",
                    height: "100%",
                    objectFit: "cover",
                  }}>

                  </img>
                  <img alt="ice" style={{
                    borderRadius: 20,
                    minHeight: '30vh',

                    maxHeight: "30vh",
                    height: "100%",
                    width: "50%",
                    objectFit: "cover"
                  }} src={"https://dryicenationwide.co.uk/wp-content/uploads/2020/04/slider_3.jpg"}></img>
                </HStack>
                <HStack alignItems="center" justifyContent="center" h="100%">
                  <VStack alignItems="start" bg="rgba(255,255,255,1)" rounded="2xl" p={8} h="100%" w={showHamburger ? "60%" : "100%"} className="hover-box">
                    <Heading mb="0.5em" textAlign="start" mr="0.25em" size="2xl" color="transparent" background="linear-gradient(90deg,#056cdf, red,#056cdf)" backgroundClip="text">We cater to everyone</Heading>
                    <Heading size="xl" textAlign="start">B2B</Heading>
                    <Text>We have multi-tonne capacity to cater to all kinds of industrial needs, like large scale logistics and medical usage.</Text>
                    <Heading size="xl" textAlign="start">On-trade</Heading>
                    <Text>Dry ice is increasingly used around the world in places like bars, restaurants and exhibits for visual effects that will always captivate your audience.</Text>
                    <Heading size="xl" textAlign="start">Schools</Heading>
                    <Text>We ❤️ science at DNL. And one of the best to demonstrate core scientific principles is by using dry ice for experiments in class.</Text>
                    <Heading size="xl" textAlign="start">Home use</Heading>
                    <Text>Dry ice is great for special celebrations and parties you might have at home, used for incredible atmospheric fog effects. Travelling far? Keep anything safely frozen on your journey.</Text>
                  </VStack>
                  {showHamburger ?
                    <VStack w="40%">
                      <Box justifyContent="center" display="flex" alignItems="center" maxW="100%">
                        <img alt="ice" src={"https://dryicenationwide.co.uk/wp-content/uploads/2020/07/dryiceimage32916.jpg"} style={{
                          height: "auto",
                          width: "100%",
                          borderRadius: 20
                        }}></img>
                      </Box>
                      <Box justifyContent="center" display="flex" alignItems="center" maxW="100%">
                        <img alt="ice" src={"https://dryicenationwide.co.uk/wp-content/uploads/2020/04/header.jpg"} style={{
                          height: "auto",
                          width: "100%",
                          borderRadius: 20
                        }}></img>
                      </Box>
                    </VStack> : null
                  }
                </HStack>
              </SimpleGrid>
            </Box>
          </Route>
          <Route path="/usage">
            <Usage></Usage>
          </Route>
          <Route path="/safety">
            <Safety></Safety>
          </Route>

          <Route exact path="/products">
            <Products></Products>
          </Route>
          <Route path="/products/:id">
            <ProductPage></ProductPage>
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/faqs">
            <FAQs />
          </Route>
          <Route path="/cart">
            <Cart></Cart>
          </Route>
          <Route path="/checkout">
            <Checkout></Checkout>
          </Route>
        </Switch>
      </BrowserRouter>
      <Box boxShadow="xl" mb="1em" minH="25vh" h="100%" w="80%" bg="red" rounded="2xl" margin="auto" p={8} display="flex" justifyContent="center" alignItems="center" color="white">
        <SimpleGrid columns={[1, 1, 1, 2, 2, 3]} spacing="16px" w="80%">

          <div style={{ position: "relative" }}>
            <div className="divider"></div>
            <VStack alignItems="start">
              <Heading size="md" textAlign="center">Dry Ice Nationwide Limited</Heading>
              <Text>CRN: 12257680</Text>
              <Text>VAT Number: 336562786</Text>
              <Text>Email: sales@dryicenationwide.co.uk</Text>
              <Text>Telephone: +44(0)118 934 4833</Text>
            </VStack>
          </div>
          <div style={{ position: "relative" }}>
            <div className="divider"></div>
            <VStack alignItems="start">
              <Heading size="md" textAlign="center">Navigate</Heading>
              <a href="/">Home</a>
              {Links.map((s) => (
                <a href={"/" + s.toLowerCase()}>{s}</a>
              ))}
            </VStack>
          </div>
          <VStack alignItems="start ">
            <Heading size="md">Social Media</Heading>
            <HStack>
              <img alt="instagram" src="https://img.icons8.com/color/48/000000/instagram-new--v1.png" />
              <a href="https://www.instagram.com/dryicenationwide/"><Text>Instagram</Text></a>
            </HStack>
            <HStack>
              <img alt="facebook" src="https://img.icons8.com/color/48/000000/facebook.png" />
              <a href="https://www.facebook.com/dryicenationwideuk/"><Text>Facebook</Text></a>
            </HStack>
          </VStack>
        </SimpleGrid>
      </Box>
      <Box mb="1em" height="5vh" display="flex" w="80%" justifyContent="center" alignItems="center" rounded="2xl" margin="auto" p={8}>
        <a href="https://icons8.com/icon/32323/instagram-logo"><Text mr="2em" fontSize="8px">Instagram logo icon by Icons8</Text></a>
        <a href="https://icons8.com/icon/13912/facebook"><Text fontSize="8px">Facebook logo icon by Icons8</Text></a>
      </Box>
    </ChakraProvider>
  )

}
