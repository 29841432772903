import { GoogleApiWrapper, Map, mapEventHandler, Marker, markerEventHandler } from 'google-maps-react';
import React, { Component } from 'react';

const style = {
	maxWidth: "100%",
	height: "100%",
	overflowX: "hidden",
	overflowY: "hidden"
};
const containerStyle = {
	position: "relative",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	top: 0,
	maxWidth: "100%",
	height: "600px"
};


export class MapContainer extends Component<{ google: any }>{
	onMapClicked: mapEventHandler | undefined;
	onMarkerClick: markerEventHandler | undefined;
	onInfoWindowClose: any;
	map?: google.maps.Map | google.maps.StreetViewPanorama

	render() {
		return (
			<Map google={google}
				zoom={16}
				draggable={false}
				initialCenter={{

					lat: 51.46293,
					lng: -0.79366
				}}
				onReady={(mapProps, map) => {
					this.setState({ map: map as google.maps.Map })
				}}
				style={style}
				containerStyle={containerStyle}
				onClick={this.onMapClicked}>

				<Marker onClick={this.onMarkerClick}
					title={`Dry Ice Nationwide`} />
			</Map>

		)
	}
}

const GoogleMap = GoogleApiWrapper({
	apiKey: 'AIzaSyB3ZDWyz9LTTOdAQr-wEZ4GBP288YtnalI'
})(MapContainer)


export default GoogleMap;