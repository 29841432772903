import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, Select, SimpleGrid, Spinner, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CartItem } from '../pages/cart';



const CheckoutForm = () => {

	const [showHamburger] = useMediaQuery([
		"(min-width: 1080px)",
	])
	const required = (value: any) => {
		let error;
		if (!value) {
			error = 'Required field'
		}
		return error;
	}
	const requiredEmail = (value: any) => {
		let error;
		if (!value) {
			error = 'Required field'
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = "Invalid email address"
		}
		return error;
	}


	AOS.init()
	const [succeeded, setSucceeded] = useState<string | boolean>(false);
	const [error, setError] = useState<string | null>(null);
	// eslint-disable-next-line
	const [nativeError, setNativeError] = useState<boolean | string>(false)
	const [processing, setProcessing] = useState<boolean | string>('');
	// eslint-disable-next-line
	const [disabled, setDisabled] = useState(true);
	const [clientSecret, setClientSecret] = useState('');
	const [orderDetails, setOrder] = useState<any>({});

	const cartItems = (JSON.parse(localStorage.getItem("cart") || "[]") as Array<CartItem>).map((item: CartItem) => {
		return {
			product_id: item.ID,
			quantity: item.Quantity
		}
	})
	useEffect(() => {
		// Create PaymentIntent as soon as the page loads
		window
			.fetch("https://dnl-3yxkjexpra-nw.a.run.app/api/orders/paymentIntent", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					delivery_type: localStorage.getItem("delivery_type"),
					items: cartItems
				})
			})
			.then(res => {
				return res.json();
			})
			.then(data => {
				setClientSecret(data);
			});
		// eslint-disable-next-line
	});

	const cardStyle: StripeCardElementOptions = {
		iconStyle: 'solid',
		style: {
			base: {
				padding: '1em',
				iconColor: 'blue',
				color: '#000000',
				fontWeight: 800,
				fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',

				':-webkit-autofill': {
					color: '#fce883',
				},
				'::placeholder': {
					color: 'black',
				},
				':hover': {
					padding: "2px",
				}
			},
			invalid: {
				iconColor: 'red',
				color: 'red',
			},
		},
	};
	const handleChange = async (event: any) => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleSubmit = async (ev: { preventDefault: () => void; }) => {
		ev.preventDefault();
		setProcessing(true);
		const payload = await stripe!.confirmCardPayment(clientSecret, {
			payment_method: {
				card: elements!.getElement(CardElement)!
			}
		});
		if (payload.error) {
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
		} else {
			setError(null)
			setProcessing(false);
			setSucceeded(true);
			localStorage.setItem("cart", "")
		}
	};

	const stripeRef = useRef<HTMLDivElement>(null)

	const scrollToStripe = () => {
		const node = stripeRef.current
		node!.scrollIntoView({ behavior: 'smooth' })
	}
	const stripe = useStripe();
	const elements = useElements();

	if (!clientSecret) {
		return (
			<Box w="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
				<Spinner />
			</Box>
		)
	}
	else {
		return (
			<SimpleGrid columns={[1, 1, 1, 2]} w={showHamburger ? "80%" : "100%"} p={8} spacingX="8px">
				<Box mb="2em">
					<Formik
						initialValues={{
							customer_type: "",
							name: "",
							email: "",
							phone: "",
							address: "",
							postcode: "",
							building_number: "",
							town: "",
							county: "",

						}} onSubmit={(values, actions) => {
							window
								.fetch("https://dnl-3yxkjexpra-nw.a.run.app/api/orders", {
									method: "POST",
									headers: {
										"Content-Type": "application/json"
									},
									body: JSON.stringify({
										customer_type: values.customer_type,
										name: values.name,
										email: values.email,
										phone: values.phone,
										address: values.address,
										postcode: values.postcode,
										building_number: values.building_number,
										town: values.town,
										county: values.county,
										delivery_date: localStorage.getItem("delivery_date"),
										delivery_type: localStorage.getItem("delivery_type"),
										items: cartItems,
										stripe_pi: clientSecret
									})
								})
								.then(res => {
									if (res.status !== 200) {
										setNativeError("There was an error processing this order. Try again or call us.")
									}
									return res.json();
								})
								.then(data => {
									actions.setSubmitting(false)
									setOrder(data)
									scrollToStripe()
								})


						}}>
						{(props) => (
							<Form>
								<VStack>
									{JSON.stringify(orderDetails) !== "{}" ? <Text>To edit the details you entered, please refresh the page.</Text> : null}
									<Field name="customer_type">
										{({ field, form }: any) => (

											<FormControl isInvalid={form.errors.customer_type && form.touched.customer_type} >
												<FormLabel htmlFor="customer_type">Customer Type</FormLabel>
												<Select>
													i				<option>Individual</option>
													<option>Business</option>
												</Select>
												<FormErrorMessage>{form.errors.customer_type}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="name" validate={required}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.name && form.touched.name}>
												<FormLabel htmlFor="name">Name</FormLabel>
												<Input {...field} id="name" placeholder="" />
												<FormErrorMessage>{form.errors.name}</FormErrorMessage>
											</FormControl>)}
									</Field>
									<Field name="email" validate={requiredEmail}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.email && form.touched.email}>
												<FormLabel htmlFor="email">Email</FormLabel>
												<Input {...field} id="email" placeholder="" />
												<FormErrorMessage>{form.errors.email}</FormErrorMessage>
											</FormControl>)}
									</Field>
									<Field name="phone" validate={required}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.phone && form.touched.phone}>
												<FormLabel htmlFor="phone">Phone</FormLabel>
												<Input {...field} id="phone" placeholder="" />
												<FormErrorMessage>{form.errors.phone}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="address" validate={required}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.address && form.touched.address}>
												<FormLabel htmlFor="address">Address</FormLabel>
												<Input {...field} id="address" placeholder="" />
												<FormErrorMessage>{form.errors.address}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="postcode" validate={required}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.postcode && form.touched.postcode}>
												<FormLabel htmlFor="postcode">Postcode</FormLabel>
												<Input {...field} id="postcode" placeholder="" />
												<FormErrorMessage>{form.errors.postcode}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="building_number" validate={required}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.building_number && form.touched.building_number}>
												<FormLabel htmlFor="building_number">Building Number</FormLabel>
												<Input {...field} id="building_number" placeholder="" />
												<FormErrorMessage>{form.errors.building_number}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="town" validate={required}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.town && form.touched.town}>
												<FormLabel htmlFor="town">Town</FormLabel>
												<Input {...field} id="town" placeholder="" />
												<FormErrorMessage>{form.errors.town}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="county" validate={required}>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.county && form.touched.county}>
												<FormLabel htmlFor="county">County</FormLabel>
												<Input {...field} id="county" placeholder="" />
												<FormErrorMessage>{form.errors.county}</FormErrorMessage>
											</FormControl>
										)}
									</Field>





								</VStack>
								{JSON.stringify(orderDetails) === "{}" ?
									<Button
										mt={4}
										colorScheme="red"
										isLoading={props.isSubmitting}
										type="submit"
									>
										Proceed
									</Button> : null}
							</Form>
						)}
					</Formik>
				</Box>
				<Box p={"4"} w="100%" display="flex" flexDirection="column" bg="white" rounded="2xl" >
					<Heading mb="0.5em">Checkout</Heading>
					<Box w="100%" py="8" maxH="35vh" overflowY="scroll" mb="2em" className="checkoutCartContainer" display="flex" flexDirection="column" alignItems="center">
						{(JSON.parse(localStorage.getItem("cart") || "[]") as Array<CartItem>).map((item: CartItem) => {
							return (
								<Box mb="1em" boxShadow="md" p="8" bg="gray.100" w="100%" rounded="2xl" className="checkoutCartItem">
									<VStack alignItems="start">
										<HStack><Text fontSize={["16px", "16px", "16px", "20px", "24px"]}>Item: </Text><Text fontSize={["16px", "16px", "16px", "20px", "24px"]}>{item.ProductName}</Text></HStack>
										<HStack><Text fontSize={["16px", "16px", "16px", "20px", "24px"]}>Quantity: </Text><Text fontSize={["16px", "16px", "16px", "20px", "24px"]}>{item.Quantity}</Text></HStack>
									</VStack>
								</Box>
							)
						})}
					</Box>
					{JSON.stringify(orderDetails) !== "{}" ?
						<div ref={stripeRef}>
							<Box p={8} bg="gray.200" rounded="2xl" >
								<Box mb={"2em"}>
									<Heading>Total Cost: £{orderDetails["Cost"]}</Heading>
									<Heading>{orderDetails.DeliveryDate}</Heading>
								</Box>
								<CardElement id="card-element" options={cardStyle} onChange={handleChange} />

								<Button
									id="submit"
									mt="2em"
									mb="1em"
									onClick={handleSubmit}
								>
									<span id="button-text">
										{processing ? (
											<div className="spinner" id="spinner">Processing</div>
										) : (
											"Pay now"
										)}
									</span>
								</Button>
								{/* Show any error that happens when processing the payment */}
								{error && (
									<div className="card-error" role="alert">
										{error}
									</div>
								)}
								{/* Show a success message upon completion */}
								{succeeded ? <Heading mt="2em" data-aos="fade-in">{orderDetails.OrderID} Payment was succesful, check your email for confirmation! (make sure to check your spam)</Heading> : null}
							</Box></div> : null}
				</Box>

			</SimpleGrid >
		)
	}
}

export default CheckoutForm