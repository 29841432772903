import { loadStripe } from "@stripe/stripe-js";
import React from "react"
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/checkoutForm";
import { Box } from "@chakra-ui/react";
const promise = loadStripe("pk_live_v3mhbol5JpEI5zWqqvmtBOfB00zkhBw6ih");

const Checkout = () => {
	return (
		<>
			<Elements stripe={promise}>
				<Box w="100%" display="flex" justifyContent="center">
					<CheckoutForm></CheckoutForm>
				</Box>
			</Elements>
		</>
	)
}

export default Checkout