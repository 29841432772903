import { Box, Button, Heading, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useMediaQuery, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";


export type CartItem = {
	ID: string,
	ProductName: string,
	Quantity: number,
	Cost: number
}

const Cart = (props: { variant?: string }) => {


	const [dt, setDt] = useState(localStorage.getItem("delivery_type"));
	const today = new Date();

	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1)

	const dayafter = new Date(tomorrow);
	dayafter.setDate(tomorrow.getDate() + 1)
	const [startDate, setStartDate] = useState(today.getHours() > 13 ? dayafter : today);

	if (localStorage.getItem("delivery_type") === null) {
		localStorage.setItem("delivery_type", "Standard/Next day")
	}
	const [showHamburger] = useMediaQuery([
		"(min-width: 1080px)",
	])
	const CalculateTotal = () => {
		let total = 0;
		for (let item of cartData) {
			total += item.Cost
		}
		return total
	}

	const removeFromCart = (item: CartItem) => {
		var cart: CartItem[] = JSON.parse(localStorage.getItem("cart") || "[]")
		var index = cart.indexOf(item)
		cart.splice(index, 1)
		localStorage.setItem("cart", JSON.stringify(cart))
		window.location.reload()
	}

	const history = useHistory();

	const routeChange = () => {
		let path = `/checkout`;
		history.push(path);
	}


	const [cartData, setCartData] = useState<CartItem[]>([])

	useEffect(() => {
		let mounted = true;
		var cart = JSON.parse(localStorage.getItem("cart") || "[]")
		if (mounted) {
			setCartData(cart)
		}
		return () => {
			mounted = false
		}
	}, [startDate])

	let body;
	console.log(cartData)
	if (cartData.length === 0 || cartData === null) {
		body = (
			<Box p={16} justifyContent="center" alignItems="center" w="100vw" textAlign="center">
				<Heading>Your cart is empty</Heading>
			</Box>
		)
	} else {
		body = (
			<Box my="1em" w="100%">
				<Table variant="striped" fontSize={["12px", "14px", "16px", "20px", "24px"]} margin="auto">
					<Thead>
						<Tr>
							<Th fontSize={["8px", "10px", "12px", "14px", "16px"]} >
								Product
							</Th>
							<Th fontSize={["8px", "10px", "12px", "14px", "16px"]}>
								Quantity
							</Th >
							<Th fontSize={["8px", "10px", "12px", "14px", "16px"]}>
								Cost
							</Th>
							<Th fontSize={["8px", "10px", "12px", "14px", "16px"]}>
								Delete
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{cartData.map((product: CartItem) => (
							<Tr boxShadow="xl" rounded="2xl">
								<Td>{product.ProductName}</Td>
								<Td>{product.Quantity}</Td>
								<Td>£{product.Cost}</Td>
								<Td><Button onClick={() => removeFromCart(product)}>X</Button></Td>
							</Tr>
						)
						)}
					</Tbody>

				</Table>
				<Box>
				</Box>
			</Box >
		)
	}

	const ChangeDeliveryType = (e: React.SyntheticEvent<HTMLSelectElement, Event>) => {
		setDt(e.currentTarget.value)
		localStorage.setItem("delivery_type", e.currentTarget.value)
	}

	return (
		<Box w="80%" margin="auto" overflowX="hidden" display="flex" flexDirection="column" alignItems="center" mb="1em">

			{body}
			{(cartData.length > 0) ?
				<VStack width="100%" rounded="2xl" alignItems="center" p={4} bg="#056cdf" color="white" spacing="16px">
					<Box display="flex" flexDir={showHamburger ? "row" : "column"}>
						<Box boxShadow="md" margin="0.5em" bg="white" rounded="2xl" p={8}>
							<Heading color="#056cdf" size="md">Total cost before shipping: £{CalculateTotal()}</Heading>
						</Box>
						<Box boxShadow="md" margin="0.5em" bg="white" rounded="2xl" p={8}>
							<Heading color="#056cdf" size="md">Shipping cost: {dt === "Same day collection" ? <>£20</> : <>Free</>}</Heading>
						</Box>
						<Box boxShadow="md" margin="0.5em" bg="white" rounded="2xl" p={8}>

							<Heading color="#056cdf" size="md">Delivery on: {startDate.toDateString()}</Heading>
						</Box>
					</Box>
					<Box bg="red" p={8} rounded="2xl" w="100%">
						<Text mb=".5em">Select delivery type</Text>
						<Text mb=".5em">Standard and next day delivery is free, although next day delivery is only available before 1pm. Same day collection will cost £20, and is not available after 1pm either.</Text>
						<Select mb="0.5em" onChange={ChangeDeliveryType}>{
							today.getHours() < 13 ?
								<>
									<option value="Standard/Next day">Standard/Next day</option>
									<option value="Same day collection">Same day collection</option>
								</>
								:
								<>
									<option value="Standard/Next day">Standard/Next day</option>
									<option value="Same day collection" disabled>Same day collection</option>
								</>

						}
						</Select>
						<Text mb=".5em">Select delivery date</Text>

						<DatePicker selected={startDate}

							minDate={(dt === "Same day collection") ? today : (today.getHours() < 13 ? tomorrow : dayafter)}
							onChange={(date: Date) => {
								localStorage.setItem("delivery_date", date!.toDateString())
								setStartDate(date);
							}} />
					</Box>
					<Button onClick={routeChange} variant="solid" bg="white" color="teal" isFullWidth={true} w="100%">Checkout</Button>
				</VStack> : null
			}
		</Box >
	)
}



export default Cart
